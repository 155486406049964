import { Point, Marker as SimpleMapsMarker } from 'react-simple-maps';

import React from 'react';
import { TOxFindUsMapMarker } from './OxFindUsMap';
import { fluidSizing } from 'src/utils';
import styled from 'styled-components';

const StyledMarker = styled(SimpleMapsMarker)`
    text {
        ${fluidSizing([{ prop: 'font-size', values: [30, 30, 18, 18] }])}
    }
`;

type TMarkerOffsetsData = {
    big: {
        offsetX?: number;
        offsetY?: number;
    };
    small: {
        offsetX?: number;
        offsetY: number;
    };
};

const markerTypeOffsets: TMarkerOffsetsData = {
    big: {
        offsetY: -47
    },
    small: {
        offsetY: -24
    }
};

type TProps = {
    fill: string;
    marker: TOxFindUsMapMarker;
    onMarkerClick: (marker: TOxFindUsMapMarker) => void;
};

export const OxFindUsMapMarker = ({ fill, marker, onMarkerClick }: TProps): JSX.Element => {
    const markerTypeOffset = marker.isClickable ? markerTypeOffsets.big : markerTypeOffsets.small;

    const offsetX = (marker.offsetX || 0) + (markerTypeOffset.offsetX || 0);
    const offsetY = (marker.offsetY || 0) + (markerTypeOffset.offsetY || 0);

    return (
        <StyledMarker key={marker.name} coordinates={marker.coordinates as Point}>
            <g
                transform={`translate(${offsetX},${offsetY})`}
                style={{ cursor: marker.isClickable ? 'pointer' : 'auto' }}
            >
                {marker.isClickable && (
                    <g transform={`translate(0, -4)`}>
                        <text dominantBaseline="middle" textAnchor="middle" style={{ fill }}>
                            {marker.name}
                        </text>
                    </g>
                )}
                <g transform={`scale(${marker.isClickable ? '2' : '1'}) translate(-7, 6)`}>
                    <path
                        d="M6.84850596,9.93554663 C4.90005667,9.93554663 3.31449379,8.37428196 3.31449379,6.45569202 C3.31449379,4.53710207 4.90005667,2.97583741 6.84850596,2.97583741 C8.79695524,2.97583741 10.3825181,4.53710207 10.3825181,6.45569202 C10.3825181,8.37428196 8.79695524,9.93554663 6.84850596,9.93554663 M13.0912287,4.40944567 C12.4843319,2.47823255 10.8888767,0.906763327 8.92764145,0.30913389 C6.85187069,-0.324045851 4.64329394,0.0278458356 2.95756297,1.25093533 C1.28421422,2.46557624 0.285326087,4.41136731 0.285326087,6.45569202 C0.285326087,7.8660087 0.738420962,9.2058874 1.59531738,10.3302445 L6.84850596,16.9634409 L12.1017282,10.3297475 C13.384095,8.64616062 13.7449626,6.48822734 13.0912287,4.40944567"
                        fill={fill}
                    ></path>
                </g>
                {marker.name && (
                    <g
                        transform={`translate(-50, -20)`}
                        onClick={(): void => onMarkerClick(marker)}
                    >
                        <rect y="0" x="0" height="70" width="100" fill="transparent" />
                    </g>
                )}
            </g>
        </StyledMarker>
    );
};
