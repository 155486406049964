import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { OxComponentContainer } from 'src/components/OxComponentContainer';
import { OxFindUs } from 'src/components/OxFindUs';
import { OxBackToTop } from 'src/components/OxBackToTop';
import { WhyInjectables } from 'src/components/Marketing/WhyInjectables';
import { WhoWeAre } from 'src/components/Marketing/WhoWeAre';
import { BookAnAppointment } from 'src/components/Marketing/BookAnAppointment';
import { Hero } from 'src/components/Marketing/Hero';
import { AdvisoryPanel } from 'src/components/Marketing/AdvisoryPanel';
import { OxPageHelmet } from 'src/components/OxPageHelmet';

const Marketing5Page = (): JSX.Element => {
    const data = useStaticQuery(graphql`
        query {
            storyDesktopImage: file(relativePath: { eq: "Menu-Image-Desktop.jpg" }) {
                childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                }
            }
            storyTabletImage: file(relativePath: { eq: "Menu-Image-Tablet.jpg" }) {
                childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                }
            }
            storyTabletImage2: file(relativePath: { eq: "homepage/BookingBanner-Tablet.png" }) {
                childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                }
            }
            storyMobileImage2: file(relativePath: { eq: "homepage/BookingBanner-Mobile.png" }) {
                childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                }
            }
            drSabikaMobileImage: file(
                relativePath: { eq: "doctors/Doctor-DrSabika-Mobile-1100x800.png" }
            ) {
                childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                }
            }
            drSabikaDesktopImage: file(
                relativePath: { eq: "doctors/Doctor-DrSabika-Desktop-960x870.png" }
            ) {
                childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                }
            }
            drSabikaQaDesktop: file(
                relativePath: { eq: "doctors/Doctor-BIO-DrSabika-QA-Desktop.png" }
            ) {
                childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                }
            }
            drSabikaQaMobile: file(
                relativePath: { eq: "doctors/Doctor-BIO-DrSabika-QA-Mobile.png" }
            ) {
                childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                }
            }
        }
    `);

    const storyImages = [
        {
            ...data.storyDesktopImage.childImageSharp.gatsbyImageData,
            media: `(max-width: 767px)`
        },
        {
            ...data.storyTabletImage.childImageSharp.gatsbyImageData,
            media: `(max-width: 767px)`
        }
    ];

    return (
        <>
            <OxPageHelmet
                title="5 | Ouronyx | Luxury Facial Aesthetics"
                description="Ouronyx is the next generation of facial aesthetics. We’re the first global aesthetics destination, helping people to self-optimise with highly personalised, non-surgical treatments, delivered by the world’s best-in-class Doctors."
                keywords="Aesthetic doctor, non surgical, bespoke treatments, luxurious, St James, London, inspiring, leaders"
            />

            <OxComponentContainer marginBottom={[40, 40, 40]}>
                <Hero />
            </OxComponentContainer>

            <OxComponentContainer marginBottom={[40, 40, 40]}>
                <WhoWeAre
                    fluidImages={storyImages}
                    title="Why Ouronyx?"
                    description="Ouronyx is the first global destination focused exclusively on facial aesthetics. Ouronyx takes a fresh approach, by honouring the uniqueness of the individual, helping them achieve their full potential. Working with visionary and highly trained doctors in the world of facial aesthetics, the approach aims to disrupt the industry by creating a truly transformative facial aesthetic experience."
                />
            </OxComponentContainer>

            <OxComponentContainer marginBottom={[40, 40, 40]}>
                <WhyInjectables />
            </OxComponentContainer>

            <OxComponentContainer marginBottom={[40, 40, 40]}>
                <AdvisoryPanel primarySliderKey="dr-iman" />
            </OxComponentContainer>

            <OxComponentContainer marginBottom={[40, 40, 40]}>
                <OxFindUs lineHeaderTitle="Find us" sliderControlsPosition="bottom-outside" />
                {/* <OxTextWithSlider
          title="Dubai Coming Soon"
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
        /> */}
            </OxComponentContainer>

            <OxComponentContainer marginBottom={[40, 40, 40]}>
                <BookAnAppointment />
            </OxComponentContainer>

            <OxBackToTop />
        </>
    );
};

export default Marketing5Page;
