import { EFontWeight, EFonts } from 'src/config/enums';
import styled, { css } from 'styled-components';

import { OxCarousel } from 'src/components/OxCarousel';
import { createTextStyle } from 'src/utils/createTextStyle';
import { fluidSizing } from 'src/utils';
import { hoverUnderlineAnimation } from 'src/styles/animations';

export const Content = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    ${fluidSizing([{ prop: 'padding-top', values: [10, 10, 10] }])}
`;

export const MapWithSliderContainer = styled.div(
    ({ theme }) => css`
        display: flex;
        ${theme.breakpoints.only('xs')} {
            flex-direction: column;
        }
    `
);

export const Map = styled.div(
    ({ theme }) => css`
        width: 100%;
        display: flex;
        flex-direction: column;
        ${theme.breakpoints.up('s')} {
            flex: 0 0 50%;
            width: 50%;
        }
    `
);

export const MapLabel = styled.span(
    ({ theme }) => css`
        color: ${theme.colors.primary.main};
        text-transform: uppercase;
        ${createTextStyle(EFonts.Montserrat, EFontWeight.Medium, [8, 8, 8], [14, 14, 14])}
    `
);

export const Location = styled.div(
    ({ theme }) => css`
        display: flex;
        flex-direction: column;
        ${theme.breakpoints.up('s')} {
            flex: 0 0 50%;
            width: 50%;
        }
    `
);

export const LocationDetails = styled.div(
    ({ theme }) => css`
        display: flex;
        justify-content: space-between;
        ${theme.breakpoints.up('s')} {
            flex-direction: column;
        }
    `
);

export const LocationDetailsContainer = styled.div<{
    locationDescription: boolean;
}>(
    ({ theme, locationDescription }) => css`
    display: flex;
    flex-direction: row-reverse;
    ${theme.breakpoints.up('s')} {
      & > *:first-child {
        width 100%;
      }
    }
    ${
        locationDescription &&
        css`
            flex-direction: row;
            justify-content: space-between;
        `
    }
    ${theme.breakpoints.only('xs')} {
      flex-direction: column;
    }
  `
);

export const LocationDescription = styled.div(
    ({ theme }) => css`
        width: 50%;
        ${createTextStyle(EFonts.Montserrat, EFontWeight.Light, [12, 13, 13], [20, 20, 20])}
        ${fluidSizing([{ prop: 'padding-top', values: [10, 10, 10] }])}
${theme.breakpoints.only('xs')} {
            width: 100%;
        }
    `
);

export const Slider = styled(OxCarousel)`
    display: flex;
    flex: 1;
    ${fluidSizing([{ prop: 'margin-bottom', values: [10, null, null] }])}
`;

export const Title = styled.h3`
    ${createTextStyle(EFonts.Hatton, EFontWeight.Medium, [20, 23, 24], [28, 47, 30])};
    ${fluidSizing([
        { prop: 'margin-top', values: [null, 7, 7] },
        { prop: 'margin-bottom', values: [null, 7, 7] }
    ])}
`;

export const Paragraph = styled.p`
    ${createTextStyle(EFonts.Montserrat, EFontWeight.Light, [10, 10, 12], [16, 16, 18])}
`;

export const Details = styled.div(
    ({ theme }) => css`
        display: flex;
        flex-direction: column;
        ${theme.breakpoints.up('s')} {
            flex-direction: row;
            align-items: flex-end;
            flex-wrap: wrap;
        }
    `
);

export const Address = styled.div`
    display: flex;
    flex-direction: column;
    ${fluidSizing([
        { prop: 'margin-right', values: [null, 20, 20] },
        { prop: 'margin-bottom', values: [8, null, null] }
    ])}
`;

export const Contact = styled.div(
    ({ theme }) => css`
        display: flex;
        flex-direction: column;
        span {
            display: flex;
            align-items: center;
            ${fluidSizing([{ prop: 'margin-bottom', values: [4, null, null] }])}
        }
        a {
            ${hoverUnderlineAnimation({ theme }, theme.colors.basic.black)};
        }
        svg {
            ${fluidSizing([
                { prop: 'width', values: [14, 14, 14] },
                { prop: 'margin-right', values: [8, 8, 8] }
            ])}
        }
    `
);

export const Note = styled.div(
    ({ theme }) => css`
        ${fluidSizing([{ prop: 'margin-bottom', values: [4, null, null] }])}
        ${theme.breakpoints.up('s')} {
            flex: 0 0 100%;
        }
    `
);
