import * as Styled from './OxFindUs.styled';

import { EOxCarouselItemType, TOxCarouselItem } from 'src/components/OxCarousel';
import { OxFindUsMap, TOxFindUsMapMarker } from './components/OxFindUsMap';
import React, { useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { OxContainer } from 'src/components/OxContainer';
import { OxIcon } from 'src/components/OxIcon';
import { OxLineHeader } from 'src/components/OxLineHeader';
import { LocationsData, TLocationDataItem } from 'src/constants/locations';
import { getGatsbyImageDataFromImage } from 'src/services/cms/dataConverter';

type TSliderItems = {
    [key: string]: TOxCarouselItem[];
};

type TMarkersData = TMarkersDataItem[];

type TMarkersDataItem = {
    key: string;
    name: string;
    isClickable?: boolean;
    coordinates: number[];
    offsetY?: number;
    offsetX?: number;
};

type TProps = {
    lineHeaderTitle: string;
    locationDescription?: string;
    sliderControlsPosition: 'bottom-inside' | 'bottom-outside';
};

export const OxFindUs = (props: TProps): JSX.Element => {
    const data = useStaticQuery(
        graphql`
            query {
                findUsLondonImageOne: file(relativePath: { eq: "locations/london.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH)
                    }
                }
                findUsDubaiImageOne: file(relativePath: { eq: "locations/dubai.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH)
                    }
                }
                findUsPlaceholderImage: file(
                    relativePath: { eq: "homepage/Location-Placeholder.png" }
                ) {
                    childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH)
                    }
                }
            }
        `
    );

    const sliderItems: TSliderItems = {
        london: [
            {
                type: EOxCarouselItemType.FluidImage,
                fluidImages: [
                    {
                        ...getGatsbyImageDataFromImage(data.findUsLondonImageOne)
                    }
                ]
            }
        ],
        dubai: [
            {
                type: EOxCarouselItemType.FluidImage,
                fluidImages: [
                    {
                        ...getGatsbyImageDataFromImage(data.findUsDubaiImageOne)
                    }
                ]
            }
        ]
    };

    const markers: TMarkersData = [
        {
            key: 'london',
            name: 'London',
            isClickable: true,
            coordinates: [-0.076132, 51.50853],
            offsetX: -2
        },
        {
            key: 'dubai',
            name: 'Dubai',
            coordinates: [55.277397, 25.199514]
        },
        {
            key: 'moscow',
            name: 'Moscow',
            coordinates: [37.620407, 55.754093]
        },
        {
            key: 'tokyo',
            name: 'Tokyo',
            coordinates: [139.839478, 35.652832]
        },
        {
            key: 'shanghai',
            name: 'Shanghai',
            coordinates: [121.522179, 31.267401]
        }
    ];

    const [selectedMarker, setSelectedMarker] = useState<TOxFindUsMapMarker>(markers[0]);
    const onSelectedMarkerChange = (marker: TOxFindUsMapMarker): void => {
        const newMarker = markers.find((item) => item.key === marker.key);
        if (marker && marker.isClickable) setSelectedMarker(newMarker as TOxFindUsMapMarker);
    };

    const activeSliderItems: TOxCarouselItem[] = sliderItems[selectedMarker.key] || [];

    const activeLocationData: TLocationDataItem = LocationsData[selectedMarker.key];

    return (
        <OxContainer>
            <OxLineHeader>{props.lineHeaderTitle}</OxLineHeader>
            <Styled.Content>
                <Styled.MapWithSliderContainer>
                    <Styled.Map>
                        {/*
          https://app.asana.com/0/1198933413209353/1199163626652269/f
          <Styled.MapLabel type={ETextStyle.LabelXS}>
            {device.xs || device.s ? "Tap" : "Click"} on the location to
            discover
          </Styled.MapLabel> */}
                        <OxFindUsMap
                            onChange={onSelectedMarkerChange}
                            selectedMarker={selectedMarker}
                            markers={markers}
                        />
                    </Styled.Map>
                    <Styled.Location>
                        <Styled.Slider
                            items={activeSliderItems}
                            controlsPosition={props.sliderControlsPosition}
                            transitionEffect="fade"
                            dots
                            arrows
                        />
                        <Styled.LocationDetailsContainer
                            locationDescription={!!props.locationDescription}
                        >
                            <Styled.LocationDetails>
                                <Styled.Title>{activeLocationData.name}</Styled.Title>
                                <Styled.Details>
                                    {activeLocationData.address && (
                                        <Styled.Address>
                                            {activeLocationData.address.streetLines.map(
                                                (addr: string, index: number) => (
                                                    <Styled.Paragraph key={index}>
                                                        {addr}
                                                    </Styled.Paragraph>
                                                )
                                            )}
                                            {activeLocationData.address.city && (
                                                <Styled.Paragraph>
                                                    {activeLocationData.address.city}
                                                </Styled.Paragraph>
                                            )}
                                            {activeLocationData.address.postcode && (
                                                <Styled.Paragraph>
                                                    {activeLocationData.address.postcode}
                                                </Styled.Paragraph>
                                            )}
                                        </Styled.Address>
                                    )}
                                    <Styled.Contact>
                                        {/*<span>*/}
                                        {/*  <OxIcon name="call" />*/}
                                        {/*  <a*/}
                                        {/*    href={`tel:${activeLocationData.phone.replace(*/}
                                        {/*      " ",*/}
                                        {/*      ""*/}
                                        {/*    )}`}*/}
                                        {/*  >*/}
                                        {/*    <Styled.Paragraph>*/}
                                        {/*      {activeLocationData.phone}*/}
                                        {/*    </Styled.Paragraph>*/}
                                        {/*  </a>*/}
                                        {/*</span>*/}
                                        <span>
                                            <OxIcon name="mail" />
                                            <a href={`mailto:${activeLocationData.email}`}>
                                                <Styled.Paragraph>
                                                    {activeLocationData.email}
                                                </Styled.Paragraph>
                                            </a>
                                        </span>
                                    </Styled.Contact>
                                    {activeLocationData.note && (
                                        <Styled.Note>
                                            <Styled.Paragraph>
                                                {activeLocationData.note}
                                            </Styled.Paragraph>
                                        </Styled.Note>
                                    )}
                                </Styled.Details>
                            </Styled.LocationDetails>
                            {props.locationDescription && (
                                <Styled.LocationDescription>
                                    {props.locationDescription}
                                </Styled.LocationDescription>
                            )}
                        </Styled.LocationDetailsContainer>
                    </Styled.Location>
                </Styled.MapWithSliderContainer>
            </Styled.Content>
        </OxContainer>
    );
};
