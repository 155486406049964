import styled, { css } from 'styled-components';

export const Container = styled.div<{ showMarkers: boolean }>(
    ({ showMarkers }) => css`
        .rsm-marker {
            opacity: 0;
            transition: 400ms;
        }
        ${showMarkers &&
        css`
            .rsm-marker {
                opacity: 1;
            }
        `}
    `
);
